import { defineComponent } from 'vue';
import AnnualProductionComponent from '@/components/AnnualProduction/AnnualProductionComponent.vue';
export default defineComponent({
    name: 'AnnualProductionDuplicateDialog',
    props: {
        propSelected: Array,
        propId: String
    },
    components: { AnnualProductionComponent },
    data: function () {
        return {
            show: false,
            id: this.propId
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        showHideDialog() {
            this.show = !this.show;
        },
        createNew() {
            this.show = true;
        }
    }
});
