import { defineComponent } from 'vue';
import AnnualProductions from '@/views/AnnualProduction/AnnualProductions.vue';
export default defineComponent({
    name: 'AnnualProductionLayout',
    components: { AnnualProductions },
    data() {
        return {
            loading: false,
            tab: 'Annual Environments'
        };
    }
});
